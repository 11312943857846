import type { UnlikelyMoney } from "@unlikelystudio/commerce-connector"

import type { Nullish } from "~/@types/generics"
import serializePrice from "~/data/price/serializer"
import type { TPrice } from "~/components/ui/Price"
import { getPercentage } from "~/utils/get-percentage"

export function serializeSfPrice(
  locale: string,
  priceMoney: Nullish<UnlikelyMoney>,
  compareAtPriceMoney?: Nullish<UnlikelyMoney>
): TPrice | null {
  const price = serializePrice(locale, priceMoney?.currencyCode, priceMoney?.amount)

  if (!price) return null

  const compareAtPrice = compareAtPriceMoney?.amount
    ? serializePrice(locale, compareAtPriceMoney?.currencyCode, compareAtPriceMoney?.amount)
    : null
  const percentage =
    compareAtPriceMoney?.amount && priceMoney?.amount
      ? getPercentage(compareAtPriceMoney?.amount, priceMoney?.amount).value
      : null

  return {
    price,
    compareAtPrice,
    percentage,
  }
}
