import type { ComponentProps, PropsWithChildren } from "react"
import type { RecipeVariants } from "@vanilla-extract/recipes"
import clsx from "clsx"

import type { UnlikelyOrderFulfillmentStatus } from "@unlikelystudio/commerce-connector"

import type { AllNever, Nullish, PropsWithClassName } from "~/@types/generics"
import { fulFillmentStatus } from "~/lib/shopify/constants/fulfillment-statuses"
import { ClientTranslate } from "~/providers/I18nProvider/ClientTranslate"

import { colors } from "~/styles/variables/colors"

import * as css from "./styles.css"

type FulfillmentPresets = Record<(typeof fulFillmentStatus)[number], Required<PropsWithChildren<BadgeTagBaseProps>>>
type FulfillmentTheme = { [key in UnlikelyOrderFulfillmentStatus]?: BadgeTagBaseProps }

const themes: FulfillmentTheme = {
  FULFILLED: {
    backgroundColor: colors["black"],
    color: "white",
  },
  UNFULFILLED: {
    backgroundColor: colors["yellow-dolly"],
    color: "black",
  },
}

// Create all of our order fulfillment tag presets based on values returned by commerce-connector
const fulfillmentPresets = fulFillmentStatus.reduce<FulfillmentPresets>((acc, status) => {
  return {
    ...acc,
    [status]: {
      ...themes[status],
      children: <ClientTranslate tKey={`badge_${status.toLowerCase() as Lowercase<UnlikelyOrderFulfillmentStatus>}`} />,
      withBorder: false,
      borderColor: "transparent",
    },
  }
}, {} as FulfillmentPresets)

const presets = {
  out_of_stock: {
    backgroundColor: colors.black,
    color: "white",
    children: <ClientTranslate tKey="badge_out_of_stock" />,
    withBorder: false,
    borderColor: "transparent",
  },
  main_address: {
    backgroundColor: colors.black,
    color: "white",
    children: <ClientTranslate tKey="badge_main_address" />,
    withBorder: false,
    borderColor: "transparent",
  },
  ...fulfillmentPresets,
} satisfies Record<string, Required<PropsWithChildren<BadgeTagBaseProps>>>

type PresetKey = keyof typeof presets

type BadgeTagRecipe = RecipeVariants<typeof css.BadgeTag>

type BadgeTagBaseProps = { backgroundColor?: string; borderColor?: string } & BadgeTagRecipe
type BadgeTagPresetProps = { preset?: PresetKey }
export type BadgeTagProps = PropsWithClassName<
  ((BadgeTagBaseProps & AllNever<BadgeTagPresetProps>) | (BadgeTagPresetProps & AllNever<BadgeTagBaseProps>)) &
    Omit<ComponentProps<"div">, "color"> & { slug?: Nullish<string> }
>

function BadgeTag({
  className,
  color,
  backgroundColor,
  borderColor,
  withBorder,
  children,
  style,
  preset,
  ...rest
}: BadgeTagProps) {
  const computedProps = preset ? presets?.[preset] : { color, backgroundColor, borderColor, withBorder, children }

  return (
    <div
      data-comp="UI/BadgeTag"
      className={clsx(
        className,
        css.BadgeTag({
          withBorder: computedProps.withBorder,
          color: computedProps.color,
        })
      )}
      style={{ ...style, backgroundColor: computedProps.backgroundColor, borderColor: computedProps.borderColor }}
      {...rest}
    >
      {computedProps.children}
    </div>
  )
}

export default BadgeTag
