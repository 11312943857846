import type { Nullish } from "~/@types/generics"
import { getIntl } from "~/lib/i18n/utils/get-i18n"
import { geti18nLocale } from "~/lib/i18n/utils/get-processed-locale"

export default function serializePrice(
  locale: Nullish<string>,
  currencyCode: Nullish<string>,
  amount: Nullish<number>,
  additionalOptions?: Intl.NumberFormatOptions
) {
  const shopifyISOCodeForTransactionsWithoutCurrency = "XXX"
  const options = {
    style: "currency",
    /**
     * Prevent XXX currency code from being displayed. XXX is use by Shopify
     * to denote transactions that involve no currency.
     * For more details : https://shopify.dev/changelog/new-code-for-unrecognized-currencies#:~:text=XXX%20is%20the,an%20input%20value.
     */
    currency: currencyCode === shopifyISOCodeForTransactionsWithoutCurrency ? "EUR" : currencyCode ?? undefined,
    useGrouping: true,
    minimumFractionDigits: 0,
    maximumFractionDigits: 2,
    ...additionalOptions,
  }

  if (!locale || !currencyCode || isNaN(Number(amount))) {
    return null
  }

  const intl = getIntl(locale)
  const isSwissLocale = (geti18nLocale(locale) as string) === "fr-ch" || (geti18nLocale(locale) as string) === "en-ch"

  return isSwissLocale
    ? new Intl.NumberFormat(intl, options).format(Number(amount))
    : new Intl.NumberFormat(intl, options)
        .format(Number(amount))
        // Remove unbreakable space
        .replace(/\u00a0/g, "")
}
