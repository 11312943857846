"use client"

import type { ElementType } from "react"
import type { InterpolationOptions } from "node-polyglot"

import type { Dictionary } from "~/providers/I18nProvider/constants"
import { useTranslate } from "~/providers/I18nProvider/hooks/useTranslate"

type ClientTranslateProps = {
  tKey: keyof Dictionary
  options?: number | InterpolationOptions
  as?: ElementType
}

export function ClientTranslate({ as: Tag, ...props }: ClientTranslateProps) {
  const { tKey, options } = props ?? {}

  const t = useTranslate()

  return Tag ? <Tag>{t(tKey, options)}</Tag> : <>{t(tKey, options)}</>
}
