"use client"

import { useGetCustomer } from "@unlikelystudio/react-ecommerce-hooks"

import type { ExplicitAny } from "~/@types/generics"
import { getReferenceIdFromGID } from "~/lib/shopify/utils/id"
import useLocale from "~/hooks/useLocale"
import { type TrackingEventKey, type TrackingEvents } from "~/providers/GTMTrackingProvider/constants"
import { useEventsBuffer } from "~/providers/GTMTrackingProvider/hooks/use-events-buffer"

export function useTracking() {
  const locale = useLocale()
  const splitLocale = locale.split("-")
  const lang = splitLocale?.[0] ?? undefined
  const market = splitLocale?.[1]?.toUpperCase() ?? undefined
  const { data: customer } = useGetCustomer()

  const setEventsBuffer = useEventsBuffer()[1]

  function pushToDataLayer(data: Record<string, ExplicitAny>) {
    if (window?.dataLayer) {
      if (data.ecommerce) {
        window.dataLayer.push({ ecommerce: null })
      }
      window.dataLayer.push({
        ...data,
        ...(data.event === "page_view"
          ? {
              user_email: customer?.email ?? null,
              user_id: getReferenceIdFromGID(customer?.id) ?? null,
            }
          : {}),
      })
    } else {
      setEventsBuffer((prev) => [...prev, data])
    }
  }

  function sendEvent<TKey extends TrackingEventKey>(
    event: TKey,
    ...params: TrackingEvents[TKey] extends Record<string, ExplicitAny> ? [TrackingEvents[TKey]] : []
  ) {
    pushToDataLayer({ event, market, locale: lang, ...params[0] })
  }

  return { sendEvent, pushToDataLayer }
}
