import clsx from "clsx"

import { Image, type ImageProps } from "~/components/ui/Image"

import { sprinkles, type Sprinkles } from "~/styles/sprinkles.css"

import * as css from "./styles.css"

export function FillImage(props: { objectFit?: Sprinkles["objectFit"] } & Omit<ImageProps, "fill">) {
  const { width: _width, height: _height, className, imageClassName, objectFit = "cover", ...rest } = props

  return (
    <Image
      className={clsx(css.container, className)}
      imageClassName={clsx(imageClassName, sprinkles({ objectFit }))}
      fill={true}
      {...rest}
    />
  )
}
