"use client"

import { useHydrateAtoms } from "jotai/utils"

import type { InternalRedirects } from "~/managers/InternalRedirectsManager/_data/serializer"
import { internalRedirectsAtom } from "~/managers/InternalRedirectsManager/store"

function ClientInternalRedirectsManager({ redirects }: { redirects: InternalRedirects }) {
  useHydrateAtoms([[internalRedirectsAtom, redirects]])
  return <></>
}

export { ClientInternalRedirectsManager }
